@mixin removeSpacing {
	margin: 0;
	padding: 0;
}

@mixin flexToCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

