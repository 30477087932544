.prize-component-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
}

.prize-stand {
  width: 80%;
}

.grand-prize-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prize-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.prize-component-grand-prize-text {
  color: white;
  font-weight: 700;
  font-family: VAGRounded;
  font-size: 35px;
  text-align: center;
  text-shadow: 0px 4px 4px #0F6DC2;
}



.prize-component-grand-prize-image {
  width: auto;
  height: auto;
  position: relative;
  top: 45px;
  max-height: 266px;
}

.prize-component-multi-prize-image {
  width: 60%;
  height: auto;
  position: relative;
  top: 30px;
  left: 8%;
  max-height: 266px;
}

.prize-component-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
}

.prize-component-single-description {
  margin: 0;
  color: white;
  font-size: 13px;
  font-weight: 700;
  font-family: VAGRounded;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.prize-component-and-more-message {
  color: white;
  font-size: 20px;
  text-align: center;
  font-family: VAGRounded;
  font-weight: 700;
}

.prize-component-and-more-message-highlight {
  color: #fee42c;
}

.how-to-win-component-container {
  width: 100%;
  background-size: cover;
  height: auto;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto;
}

.mobile-how-to-win-container {
  width: 100%;
  height: 100%;
  position: absolute;
}

.how-to-win-details-container {
  display: flex;
  justify-content: center;
  padding: 0 5px 30px 5px;
}

.how-to-win-title {
  color: #0033a1;
  font-weight: 700;
  font-size: 26px;
  position: absolute;
  top: 10px;
  width: fit-content;
  margin: 0;
}

.how-to-win-step-by-steps {
  position: relative;
  width: 90%;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 75px;
}

.how-to-win-step {
  font-size: 15px;
  font-weight: 700;
  font-family: VAGRoundedBT;
  color: #0163bf;
}

.how-to-win-bold {
  text-shadow: 0.5px 0.5px 0.5px rgba(1, 99, 191);
}

.how-to-win-reward-list-container {
  display: flex;
  align-items: center;
}

.how-to-win-reward-list-image {
  height: auto;
  width: 47px;
}

.how-to-win-reward-list-text {
  font-family: VAGRoundedBT;
  color: #0163bf;
  font-size: 15px;
}

.prize-component-first-half {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.prize-component-second-half {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.how-to-win-component-container-image {
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
}
.grand-prize-component-single-description {
  margin: 0;
  color: white;
  font-size: 13px;
  font-weight: 700;
  font-family: VAGRounded;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: inline-block;
  padding: 10px 0;
}
.prize-component-container-full {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

@media only screen and (min-width: 768px) {
  .how-to-win-component-container-image {
    display: block;
  }

  .how-to-win-component-container {
    width: 100%;
    background-size: cover;
    background-image: none;
    box-sizing: border-box;
  }

  .prize-component-container {
    height: auto;
  }

  .prize-component-container-full {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 20px 0;
    height: auto;
  }

  .prize-component-first-half, .prize-component-second-half {
    width: 100%;
    height:100%;
    display: grid;
    grid-template-rows: 0.5fr 3fr 1fr;
    align-items: flex-start;
  }

  .prize-component-multi-prize-image {
    top: -5%;
    z-index: 2;
    width: 80%
   }

   .prize-stand {
    width: 90%;
    position: absolute;

   }

   
.prize-component-grand-prize-image { 
  height: auto;
  max-height: unset;
  top: 0;
  z-index: 2;
  width: 80%;
}

  .how-to-win-title {
    top: 30px;
    display: none;
  }
  .how-to-win-reward-lists {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .how-to-win-reward-list-image {
    width: min(8vw, 86px);
  }

  .prize-component-grand-prize-text {
    font-size: min(3vw, 40px);
    display: block;
    width: 60%;
    margin: 0 auto;
  }

  .prize-component-single-description {
    font-size: min(1.5vw, 25px);
  }

  .multi-mystery-box {
    height: min(30vw, 420px);
    width: auto;
    max-width: 100%;
  }

  .grand-prize {
    width: auto;
    height: min(33vw, 450px);
  }
  .mobile-how-to-win-container {
    display: none;
  }

  .prize-image-container { 
      display: grid;
      justify-items: center;
      align-items: flex-end;
      position: relative;
  }

  .grand-prize-component-single-description {
    font-size: min(1.5vw, 25px);
    padding: 20px 0;
  }
}
