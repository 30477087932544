.mystery-box-history-container {
  background-image: url("../../assets/mysteryBoxHistoryContainer.png");
  min-height: 473px;
  height: auto;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  position: relative;
  padding: 20px;
}

.mystery-item-description {
  font-size: 10px;
  margin: 0;
}

.mystery-box-history-background {
  content: "";
  background-color: #a6dbe7;
  position: absolute;
  width: 100%;
  height: calc(100% - 400px);
}
.mystery-box-history-item-list {
  width: 95%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 22%;
}

.mystery-box-history-item {
  background-color: white;
  position: relative;
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  width: 100%;
  margin: 0 auto;
  border-radius: 15px;
  height: auto;
  min-height: 120px;
  max-height: 200px;
}

.bolder {
  font-weight: bold;
}

.mystery-item-image {
  width: 20%;
  height: auto;
}

.mystery-item-title,
.mystery-item-date,
.mystery-item-value,.mystery-item-description, .mystery-item-voucher  {
  margin: 0;
  color: #022892;
  font-family: VAGRounded;
}

.mystery-item-title-and-date {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: left;
  width: 70%;
  color: #405AA4;
}

.mystery-item-exp-date {
  color: #7D7D7D;
  font-weight: bold;
  
}

.mystery-item-date {
  color: #405AA4;
  font-weight: 300;
}

.mystery-item-title{
  font-size: 18px;
  font-weight: 700;
}

.mystery-item-voucher {
  font-size: 18px;
  font-weight: 300;
}

.mystery-item-date,
.mystery-item-exp-date {
  font-size: 10px;
}

.mystery-item-value {
  font-size: 16px;
  font-weight: 900;
  text-align: right;
}

.mystery-box-history-button {
  background-color: #fea30b;
  text-decoration: none;
  color: white;
  height: 40px;
  width: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 18px;
  font-family: VAGRounded;
  font-weight: 700;
  border: 0;
  margin: 20px 0;
  z-index: 1;
  position: relative;
}

.invisible-button {
  opacity: 0;
}

@media only screen and (min-width: 768px) {
  .mystery-item-value-container {
    width: 20%;
  }
  .mystery-box-history-item-list {
    max-width: 1118px;
    padding-top: 18%;
  }

  .mystery-box-history-item {
    height: auto;
    gap: 10%;
    min-height: 180px;
  }

  .mystery-item-image {
    width: 90px;
    height: 90px;
    object-fit: contain;
  }
  .mystery-box-history-button {
    margin: 40px 0;
    font-size: 20px;
    width: 497px;
    padding: 8px;
    height: 68px;
  }

  .mystery-item-title, .mystery-item-voucher {
    font-size: 25px;
  }

  .mystery-item-value {
    font-size: 18px;
  }

  .mystery-item-date, .mystery-item-exp-date  {
    font-size: 15px;
  }

  .mystery-item-description {
    font-size: 16px;
  }


}
