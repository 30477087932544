.mystery-box-modal {
  background: rgba(0, 0, 0, 0.5);
  padding: 0;
}

.mystery-box-modal-body {
  position: relative;
  padding: 0;
  max-width: 377px;
}

.mystery-box-modal-img {
  height: auto;
  width: 80%;
}

.mystery-box-modal .modal-content {
  background-color: transparent;
  justify-content: center;
  align-items: center;
  border: 0;
}

.mystery-box-modal .modal-dialog {
  box-sizing: border-box;
}

.mystery-box-modal-content {
  background-image: url("../../assets/mysteryBoxModal.png");
  height: 352px;
  width: 377px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
}

.mystery-box-modal-close-button {
  background-color: white;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 28px;
  height: 28px;
  top: 40px;
  right: 20px;
  z-index: 3;
}

.mystery-box-modal-close-button button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0.5em !important;
  margin: 0 !important;
}

.mystery-box-modal-title {
  height: auto;
  width: 80%;
  position: absolute;
  top: 33%;
}
.mystery-box-modal-description-background {
  background-color: #d8f2ff;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1;
  font-size: 10px;
  color: #0033a1;
  font-family: DIN Round Pro;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  border-radius: 65px 65px 0 0;
  margin: 0;
  position: absolute;
  width: 70%;
  height: 250px;
  top: 18%;
  left: 15%;
}

.mystery-box-modal-description {
  width: 70%;
  height: 245px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  z-index: -2;
  font-size: 10px;
  color: #141414;

  font-family: DIN Round Pro Med;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  border-radius: 65px 65px 0 0;
}

.mystery-box-modal-prize {
  height: 79px;
  width: 93px;
  position: absolute;
  z-index: 3;
  bottom: 24%;
  left: 40%;
}

.mystery-box-modal-button {
  width: 70%;
  height: 46px;
  background-color: #fea30b;
  font-family: M-Bold;
  color: #1e1e1e;
  font-weight: 700;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  z-index: 2;
  position: relative;
}

.mystery-box-modal-text-container {
  display: flex;
  justify-content: center;
  align-items: start;
  height: 50%;
  position: relative;
  z-index: 5;
  width: 100%;
}

.mystery-box-modal-big-description {
  font-size: 18px;
  font-family: DIN Round Pro Med;
  font-weight: 500;
  position: relative;
  margin-top: 10px;
}

.mystery-box-modal-rewards-counts-description {
  font-size: 20px;
  font-weight: 700;
  width: 270px;
  font-family: Din Round Pro;
  position: relative;
  bottom: 0;
}

.mystery-box-modal-count-display {
  font-size: 40px;
}

.mystery-box-modal-orb-of-light-dashboard {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(252, 183, 73, 0.77) 0%,
    rgba(252, 183, 73, 0.2) 70%,
    rgba(252, 183, 73, 0.01) 100%
  );
  background-blend-mode: screen;
  width: 200px;
  height: 208px;
  position: absolute;
  top: 46%;
  left: 25%;
  z-index: 0;
}

.width-full {
  width: 100%;
}

.modal3x .modal-content {
  width: 80%;
  margin: 0 auto;
  border-radius: 15px;
}

.modal3x .modal-body {
  padding-top: 3.5rem;
}

.modal3x .modal-header {
  height: 10px;
}

.imgGIF {
  height: 60%;
  width: auto;
  flex: 1;
  object-fit: contain;
}

.modalGIF-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  position: relative;
  padding-top: 15%;
  padding-bottom: 10%;
  height: 100%;
}

.dashboard-modal-title {
  color: #223c96;
  font-size: 1.25rem;
  font-family: "Knewave", "cursive";
}

.dashboard-modal .modal-content {
  border-radius: 15px;
  margin: 0 auto;
  width: 85%;
  height: 85vh;
  max-height: 800px;
}

.dashboard-modal-button {
  font-size: 0.9rem !important;
}
.small-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 40px;
  margin-top: 5px;
}

.dashboard-modal-small-button {
  font-size: 0.6rem !important;
  width: 50%;
  padding: 5px !important;
  height: 100%;
  line-height: 13.48px !important;
}

.dashboard-button-container {
  width: 90%;
}

.dashboard-close-button {
  background-color: #ebebeb;
  padding: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2.5%;
  right: 5%;
  cursor: pointer;
}
.dashboard-checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.dashboard-checkbox-text {
  text-align: center;
  font-size: 0.6rem;
  font-family: VAGRounded;
  margin: 0;
}

/* @media only screen and (max-width: 768px) {
  .dashboard-modal .modal-content {
    height: 85vh;
  }
} */

@media only screen and (min-width: 768px) {
  .mystery-box-modal-count-display {
    font-size: 50px;
  }
  .mystery-box-modal-content {
    width: 557px;
    height: 519px;
  }

  .mystery-box-modal-description {
    font-size: 16px;
  }
  .mystery-box-modal-description-background {
    height: 362px;
  }
  .mystery-box-modal-prize {
    left: 40%;
    width: 169px;
    height: 131px;
    bottom: 18%;
  }
  .mystery-box-modal-close-button {
    right: 40px;
    top: 70px;
  }
  .mystery-box-modal-big-description {
    font-size: 20px;
    top: 15px;
    font-family: VAGRoundedBT;
  }
  .mystery-box-modal-orb-of-light-dashboard {
    top: 62%;
    width: 300px;
  }
  .mystery-box-modal-rewards-counts-description {
    bottom: 0;
    font-family: VAGRoundedBlack;
  }

  .mystery-box-modal-body {
    max-width: unset;
  }
  .dashboard-modal-title {
    font-size: 26px;
  }
  .dashboard-modal-button {
    font-size: 16px !important;
  }
  .dashboard-modal-small-button {
    font-size: 14px !important;
  }
  .dashboard-checkbox-text {
    font-size: 12px;
  }
}
