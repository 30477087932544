.mystery-box-home-background {
  background: linear-gradient(180deg, #0030a0 78.58%, #00113a 112.58%);
  height: auto;
  max-width: 430px;
  margin: 0 auto;
  padding-bottom: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-width: 375px;
  padding-top: 120px;
}

.actual-header-mystery-box {
  position: relative;
  z-index: 4;
}

.mystery-box-back-button {
  position: absolute;
  width: 17px;
  height: 13px;
  top: 30px;
  left: 9%;
  z-index: 2;
  cursor: pointer;
}

.mystery-box-home-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20% 0 30% 0;
  padding-top: 15%;
  background-image: url("../../../assets/newConfetti.png"),
    url("../../../assets/doorToHeaven.png");
  background-size: contain, cover;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
}

.mystery-box-home-header-giftbox {
  width: 110px;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
}

.mystery-box-home-header-title {
  width: 80%;
  max-width: 406px;
  height: auto;
  position: relative;
  z-index: 2;
}

.mystery-box-home-header-description {
  width: 70%;
  font-size: 12px;
  text-align: center;
  color: #0033a1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: DIN Round Pro Bold;
  font-weight: 700;
  bottom: 30px;
}


.mystery-box-home-header-term-and-condition {
  color: #0033a1;
  font-size: 10px;
  font-weight: 700;
  text-underline-offset: 6px;
  text-decoration-thickness: 1px;
}

.mystery-box-home-barrier {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 20px 0;
  padding-top: 10px;
  position: relative;
}

.mystery-box-home-desktop-barrier {
  display: none;
}

.mystery-box-home-barrier-giftbox {
  width: 80px;
  height: auto;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 30%;
}

.mystery-box-home-body {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: auto;
  position: relative;
  bottom: 12vh;
}

.mystery-box-home-body-title-container {
  width: 365px;
  height: 122px;
  margin: 0 auto;
  background-image: url("../../../assets/mysteryBoxHomeBodyTitle.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 10px;
  gap: 5px;
}
.mystery-box-home-body-title-double {
  width: 50%;
}
.mystery-box-home-body-title {
  margin: 0;
  font-size: 18px;
  text-align: left;
  width: 189px;
  font-weight: 700;
  font-family: VAGRoundedBT;
  background: linear-gradient(
    180deg,
    #0062bf 0%,
    #62abd4 32.9%,
    #61a9d4 56.4%,
    #0062bf 100%
  );
  -webkit-background-clip: text; /* For Safari */
  -webkit-text-fill-color: transparent; /* For WebKit browsers */
  background-clip: text; /* Standard syntax, might not work in all browsers */
}

.mystery-box-home-body-coin-logo {
  height: 75px;
  width: 20%;
  background-image: url("../../../assets/mysteryBoxHomeBodyCoinLogo.png");
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-family: M-Bold;
  font-weight: 700;
  color: white;
  background-repeat: no-repeat;
  background-position: center;
  text-shadow: -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black,
    1px 1px 0px black;
}

.mystery-box-home-circle-background {
  background-image: url("../../../assets/circleBackground.png");
  height: 815px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  z-index: -1;
  background-position: center;
  max-height: 100%;
}

.mystery-box-home-body-scroll-container {
  background-image: url("../../../assets/mysteryBoxScrollBackground.png");
  height: auto;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  margin-bottom: 30px;
  min-height: 800px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  padding-bottom: 20px;
}

.mystery-box-home-body-scroll-container-background {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
}

.mystery-box-home-body-scroll-tabs {
  background-color: #e1e1e1;
  width: 70%;
  border-radius: 25px;
  color: #a5a5a5;
}

.mystery-box-home-body-scroll-tabs .nav-item {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mystery-box-home-body-scroll-tabs .nav-link {
  color: #a5a5a5 !important;
  width: 95%;
  padding: 0;
  margin: 0;
  border-radius: 15px;
  font-size: 14px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 75px;
}

.mystery-box-home-body-scroll-tabs .nav-link.active {
  color: white !important;
  background-color: #0033a1;
  border: 0 !important;
  height: 90%;
  width: 99%;
}

.mystery-box-home-body-scroll-tabs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 60px;
}

.mystery-box-home-body-scroll-container .tab-content {
  display: flex;
  justify-content: center;
  height: auto;
  width: 100%;
}

.mystery-box-home-body-scroll-tab-item {
  width: 80%;
  padding-left: 2px;
}

.mystery-box-home-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  position: relative;
  z-index: 2;
}

.mystery-box-home-footer-button {
  width: 70%;
  height: 46px;
  background-color: #fea30b;
  font-family: M-Bold;
  color: #1e1e1e;
  font-weight: 700;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: 0;
}

.flickering-button {
  animation: fadeEffect 1s steps(5, start) infinite;
}

.mystery-box-home-footer-button-text {
  margin: 0;
  width: inherit;
  text-align: center;
}

.mystery-box-home-footer-button-logo {
  width: 29px;
  height: 29px;
}

.mystery-box-to-be-opened {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mystery-box-to-be-opened-image {
  width: 80%;
  height: auto;
  margin-bottom: 30px;
}

.mystery-box-to-be-opened-count {
  color: white;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  font-family: VAGRoundedBlack;
}
.mystery-box-to-be-opened-count-value {
  font-size: 36px;
}

.mystery-box-already-opened-count {
  color: #ff7d7a;
  margin: 0;
  font-size: 18px;
  font-family: VAGRoundedBlack;
  font-weight: 400p;
  margin-bottom: 30px;
}

.mystery-box-modal-reward-congrats {
  height: auto;
  width: 80%;
  position: absolute;
  top: 30%;
}

.mystery-box-modal-reward-container-background {
  background-color: #d8f2ff;
  display: flex;
  justify-content: center;
  align-items: end;
  z-index: 1;
  font-size: 10px;
  color: #0033a1;
  font-family: DIN Round Pro;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  border-radius: 65px 65px 0 0;
  margin: 0;
  position: absolute;
  width: 70%;
  height: 240px;
  top: 15%;
  left: 15%;
}

.mystery-box-modal-orb-of-light {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(252, 183, 73, 0.77) 0%,
    rgba(252, 183, 73, 0.2) 70%,
    rgba(252, 183, 73, 0.01) 100%
  );
  background-blend-mode: screen;
  width: 200px;
  height: 208px;
  position: absolute;
  top: 35%;
  left: 25%;
  z-index: 0;
}

.mystery-box-modal-reward-container {
  width: 70%;
  height: 245px;
  width: 70%;
  height: 245px;
  display: flex;
  justify-content: center;
  align-items: end;
  position: absolute;
  z-index: -2;
  font-size: 10px;
  color: #0033a1;
  font-family: DIN Round Pro;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  border-radius: 65px 65px 0 0;
  padding: 8%;
}

.mystery-box-modal-win-points {
  font-size: 50px;
  font-weight: 900;
  font-family: DIN Round Pro Black;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 0.9;
}

.mystery-box-modal-win-points-label {
  font-weight: 900;
  font-family: DIN Round Pro Black;
  text-align: center;
  font-size: 22px;
}

.mystery-box-modal-win-image {
  width: 184px;
  height: auto;
  position: absolute;
  top: 35%;
  left: 25%;
  z-index: 2;
}

.mystery-box-modal-reward-description {
  color: white;
  font-family: DIN Round Pro Black;
  font-weight: 700;
}

.mystery-box-modal-multi-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.mystery-box-home-background .btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: black;
  background-color: #fea30b;
  box-shadow: inherit;
}

.mystery-box-modal-multi-button-container .btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: black;
  background-color: #fea30b;
  box-shadow: inherit;
}

.mystery-box-modal .btn-secondary:hover {
  background-color: #fea30b;
  border: 0;
  color: black;
}

.mystery-box-home-footer-button:hover {
  background-color: #fea30b;
  border: 0;
  color: black;
}

.mystery-box-modal .btn-secondary:disabled {
  background-color: #5c636a;
  border: 0;
  color: black;
  opacity: 1;
}

.mystery-box-home-body-scroll-desktop-button {
  position: relative;
  z-index: 1;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  display: none;
  border-radius: 5px;
}

.desktop-navigate-button {
  width: 40%;
  font-size: 12px;
  margin: 0;
}

.mystery-box-to-be-opened-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mystery-box-banner {
  width: 100%;
  height: auto;
  margin: 20px auto;
  display: block;
  border-radius: 10px;
}
.mystery-box-home-body-inside-header {
  display: none;
}

@media only screen and (min-width: 768px) {
  .mystery-box-home-background {
    max-width: none;
    padding-bottom: 0;
  }

  .mystery-box-home-barrier {
    background-image: none;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 20px 0;
    padding-top: 10px;
    position: relative;
    height: 201px;
  }

  .mystery-box-home-desktop-barrier {
    display: block;
    position: absolute;
    height: 371px;
    width: 100%;
    top: -50px;
  }
  .mystery-box-home-header-title {
    width: 70%;
    max-width: 760px;
  }

  .mystery-box-home-header {
    background-size: contain, cover;
    padding-bottom: 10%;
  }

  .mystery-box-home-body-scroll-container {
    width: 100%;
    max-width: 1273px;
    background-image: none;
    padding-bottom: 20px;
  }
  .mystery-box-home-body-scroll-container-background {
    display: block;
  }

  .mystery-box-home-header-giftbox {
    height: 300px;
    width: auto;
  }

  .mystery-box-home-barrier-giftbox {
    height: 350px;
    width: 360px;
    left: -85px;
    top: 15%;
  }

  .mystery-box-home-body-title-container {
    width: 542px;
    height: 173px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .mystery-box-home-body-scroll-tabs {
    height: 5vw;
    max-height: 65px;
  }

  .mystery-box-home-body-scroll-tabs .nav-link {
    font-size: min(2vw, 24px);
  }

  .mystery-box-home-body-scroll-tabs-container {
    margin-top: 140px;
  }

  .navigate-button {
    display: none;
  }

  .mystery-box-home-body-scroll-desktop-button {
    display: flex;
    width: 90%;
    margin: 0 auto;
    max-width: 1273px;
  }
  .mystery-box-to-be-opened {
    flex-direction: row;
    width: 80%;
    max-width: 1273px;
  }

  .mystery-box-to-be-opened-image {
    width: 50%;
  }

  .mystery-box-to-be-opened-description {
    width: 50%;
  }

  .mystery-box-home-circle-background {
    background-size: cover;
    height: 100%;
  }
  .mystery-box-modal-reward-container-background {
    height: 362px;
  }
  .mystery-box-modal-win-image {
    width: 297.82px;
    top: 38%;
  }

  .mystery-box-modal-orb-of-light {
    width: 300px;
    top: 55%;
  }
  .mystery-box-home-header-description {
    font-size: min(2vw, 22px);
    width: 70%;
    max-width: 788px;
    z-index: 1;
  }
  .mystery-box-home-header-term-and-condition {
    font-size: min(1.5vw, 18px);
    text-decoration-thickness: 3px;
  }

  .mystery-box-home-body {
    display: none;
  }

  .mystery-box-home-body-inside-header {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: auto;
    position: relative;
  }
  .prize-component-and-more-message {
    display: none;
  }
  .mystery-box-modal-reward-container {
    height: 300px;
  }

  .mystery-box-home-footer {
    margin: 0;
  }
}
