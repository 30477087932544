$main-color: #223C96;
$primary-color: #FEA30B;
$secondary-color: #A6DBE7;
$tertiary-color: #0062BF;
$background-color: #F7F8FA;

$warning-color: #ffb828;
$danger-color: #ff0000;
$success-color: #39b44a;

$link-color: #1a8cf9;

$cAccent: #3978EF;
$cBlack: #121212;
$cGray: #333;
$cLightGray: rgba(#000, 0.1);
